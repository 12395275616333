.autoplayExitActive {
  transition: opacity 1s;
  opacity: 0;
}

.fillParentElement {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
}
