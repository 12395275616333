$bar-width: 21.25rem;

.progress-bar {
  margin: 0 0 10px;

  .fill {
    position: absolute;
    width: 67%;
    height: 100%;
    animation: indeterminate 1.8s infinite ease-in-out;
    border-radius: inherit;
    background-color: #fff;
    will-change: transform;
  }

  .bar {
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    justify-content: left;
    width: $bar-width;
    height: 1.25rem;
    overflow: hidden;
    border: 1px solid #fff;
    border-radius: 50px;
    background: radial-gradient(
      200% 200% at 50% 50%,
      rgb(255 255 255 / 0%) 0%,
      rgb(255 255 255 / 30%) 100%
    );
    box-shadow: 0 4px 8px rgb(0 0 0 / 15%);
  }
}

@keyframes indeterminate {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX($bar-width);
  }
}
