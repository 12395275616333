@import 'https://fonts.googleapis.com/css2?family=Rubik:wght@400&display=swap';

.overlay {
  display: flex;
  position: fixed;

  /* LoadRoomFrom has a z-index of 1 */
  z-index: 2;
  inset: 0;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: url('/images/loading-background.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.message {
  margin: 0;
  color: #fff;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  text-align: center;
}

.message p {
  margin: 0 0 36px;
}

.message h2 {
  font-size: 21px;
}
