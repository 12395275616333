@import 'https://fonts.googleapis.com/css2?family=Rubik:wght@400&display=swap';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.button {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  max-width: 10rem;
  height: 2.75rem;
  padding: 0 32px;
  transition: all 50ms, border 75ms, text-shadow 75ms;
  border: 1px solid #fff;
  border-radius: 50px;
  background: radial-gradient(
    55% 55% at 50% 50%,
    rgb(255 255 255 / 0%) 0%,
    rgb(255 255 255 / 30%) 100%
  );
  box-shadow: 0 4px 8px rgb(0 0 0 / 15%);
  color: #fff;
  text-decoration: none;
}

.button:hover {
  border-width: 3px;
  cursor: pointer;
}

.button > .label {
  color: #fff;
  font-family: Rubik, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  text-transform: uppercase;
  white-space: nowrap;
  user-select: none;
}

.button:hover > .label {
  text-shadow: 1px 3px rgb(0 0 0 / 15%);
}

.exitActive {
  width: 100%;
  max-width: 21.25rem;
  height: 1.25rem;
  transition: height 500ms, max-width 1s ease-in-out 500ms;
}

.exitActive:hover {
  border-width: 1px;
  cursor: default;
}

.exitActive > span {
  transition: opacity 40ms;
  opacity: 0;
}
