@import 'https://fonts.googleapis.com/css2?family=Rubik:wght@400&display=swap';

.overlay {
  display: flex;
  position: fixed;
  z-index: 2;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: black;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  font-family: Rubik, sans-serif;

  /* LoadRoomFrom has a z-index of 1 */
}

.overlay::after {
  content: ' ';
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgb(26 32 38 / 0%) -33%,
    rgb(0 0 0 / 70%) 100%
  );
}

.propertyInfo {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin: 0 0 4rem;
  color: #fff;
  text-align: center;
}

.propertyTitle {
  margin: 0 0 1rem;
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
}

.bookableRoomTitle {
  margin: 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 400;
}

.message {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 16px;
}
